<template>
  <div class="d-flex align-items-center min-vh-100">
    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol md="5">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4">
              <CForm
                  novalidate
                  @submit.prevent="submitForm()">
                <h1>Restore your login information</h1><br>
                <CInput
                  placeholder="Your Setup Code"
                  prepend=""
                  v-model="formData.setupCode.value"
                  :invalid-feedback="formData.setupCode.error"
                  :is-valid="formData.setupCode.isValid"
                />
                <CInput
                  placeholder="Your Subscription ID"
                  description="You got it right after subscribing to the service."
                  prepend=""
                  v-model="formData.subscriptionId.value"
                  :invalid-feedback="formData.subscriptionId.error"
                  :is-valid="formData.subscriptionId.isValid"
                />
                <CInput
                  placeholder="Your Email"
                  type="email"
                  description="The one you subscribed with."
                  prepend=""
                  v-model="formData.email.value"
                  :invalid-feedback="formData.email.error"
                  :is-valid="formData.email.isValid"
                />

                <CButton color="success" type="submit" block><CSpinner v-if="isLoading" color="secondary" size="sm"/> Submit</CButton>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CModal
          title="Check Your Inbox"
          color="success"
          :show.sync="isEmailSent"
      >
        Please check your email for login information. If you haven't received it, make sure you used the correct
        information in the form. Especially, pay attention to your email address. It should be the same as the one
        you specified in the first place.

        <template #footer-wrapper>
          <footer class="modal-footer">
            <slot name="footer">
              <button
                  type="button"
                  class="btn btn-success"
                  @click="isEmailSent = false">
                OK
              </button>
            </slot>
          </footer>
        </template>
      </CModal>
    </CContainer>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'LoginInformation',
  data: () => {
    return {
      isLoading: false,
      isEmailSent: false,

      formData: {
        setupCode: {
          value: '',
          error: '',
          isValid: null
        },
        subscriptionId: {
          value: '',
          error: '',
          isValid: null
        },
        email: {
          value: '',
          error: '',
          isValid: null
        },
      }
    }
  },
  methods: {
    async submitForm() {
      const isValid = this.validateForm();

      if(!isValid) return;

      try {
        this.isLoading = true;

        const response = await axios.post(
          process.env.VUE_APP_MY_API_URL + '/credentials',
          {
            subscription_id: this.formData.subscriptionId.value,
            setup_code: this.formData.setupCode.value,
            email: this.formData.email.value,
          }
        )
        console.log(response.data);

        this.isEmailSent = true;

      } finally {
        this.isLoading = false;
      }

    },
    validateForm() {
      let isValid = true;
      this.resetValidationData();

      for(const field in this.formData) {
        const fieldData = this.formData[field];

        if(!fieldData.value) {
          fieldData.isValid = false;
          fieldData.error = 'This field is required.';
          isValid = false;
        }
        else if(field === 'email' && !this.isEmail(fieldData.value)) {
          fieldData.isValid = false;
          fieldData.error = 'Email is not valid.';
          isValid = false;
        }
      }

      return isValid;
    },
    resetValidationData() {
      for(const field in this.formData) {
        this.formData[field].error = '';
        this.formData[field].isValid = null;
      }
    },
    isEmail(email) {
      return String(email)
          .toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    }
  }
}
</script>
